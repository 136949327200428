<template>
    <div>
        <v-card class="mb-5">
            <v-row no-gutters class="my-0">
                <v-col>
                    <v-card-title>Generowanie raportu</v-card-title>
                    <v-card-subtitle class="pb-2">Generowanie i pobieranie raportów ilosciowych</v-card-subtitle>
                </v-col>
                <v-col align-self="center">
                    <v-card-actions class="justify-end">
                <v-btn
                    to="/"
                    class="ml-3"
                    dark
                    small>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
            <v-card-text class="pt-2">
                <p>Wybór kierowcy jak i zakresu dat nie jest obowiązkowy. Gdy kierowca zostanie wybrany generuje się raport dla konkretnego kierowcy, a jeżeli nie to ogólny.<br>
                    <strong>UWAGA</strong> nie podanie dat domyślnie generuje ostatnie 30 dni.</p>
                <v-form
                    autocomplete="off"
                    @submit.prevent="generateRaport">
                    <div>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                    label="Kierowca"
                                    hint="Wyszukaj kierowce po numerze na kogucie"
                                    persistent-hint
                                    :items="listDriver"
                                    v-model="driverID"
                                    dense
                                    clearable
                                    item-text="driver_number"
                                    prepend-icon="mdi-taxi"
                                    item-value="id"
                                    outlined
                                >
                                    <template v-slot:selection="data">
                                        {{ data.item.driver_number }} - {{ data.item.first_name }} {{ data.item.last_name }}
                                    </template>
                                    <template v-slot:item="data">
                                        {{ data.item.driver_number }} - {{ data.item.first_name }} {{ data.item.last_name }}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3" class="py-0">
                                <v-text-field
                                    type="date"
                                    v-model="dateFrom"
                                    label="Data od"
                                    :max="dateTo ? dateTo : maxDate"
                                    hint="Format: YYYY-MM-DD"
                                    persistent-hint
                                    clearable
                                    prepend-icon="mdi-calendar-range"
                                    dense
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" md="3" class="py-0">
                                <v-text-field
                                    type="time"
                                    v-model="timeFrom"
                                    label="Od godziny"
                                    hint="Format: GG:MM"
                                    persistent-hint
                                    clearable
                                    prepend-icon="mdi-clock-outline"
                                    dense
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" md="3" class="py-0">
                                <v-text-field
                                    type="date"
                                    v-model="dateTo"
                                    label="Data do"
                                    :min="dateFrom"
                                    :max="maxDate"
                                    hint="Format: YYYY-MM-DD"
                                    persistent-hint
                                    clearable
                                    prepend-icon="mdi-calendar-range"
                                    dense
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" md="3" class="py-0">
                                <v-text-field
                                    type="time"
                                    v-model="timeTo"
                                    label="Do godziny"
                                    hint="Format: GG:MM"
                                    persistent-hint
                                    clearable
                                    prepend-icon="mdi-clock-outline"
                                    dense
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" class="align-self-center pb-0">
                                <v-btn
                                    tile
                                    class="d-block ml-auto"
                                    color="primary"
                                    type="submit">Generuj</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
        <v-card class="mt-5">
            <v-card-title>Lista raportów</v-card-title>
            <v-card-text class="px-0 py-0">
                <v-data-table
                    :headers="headers"
                    :items="listRaport"
                    :items-per-page="5"
                    :footer-props="{
                        itemsPerPageOptions: [25, 50, 100],
                    }"
                    dense
                >
                    <template v-slot:[`item.file_url`]="{ item }">
                        <v-btn
                            small
                            depressed
                            target="_blank"
                            color="primary darken-1"
                            :href="item.file_url"
                        >
                            Pobierz raport
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: "Raport",
        props: {
            listDriver: {
                type: Array,
                required: true
            },
            message: {
                type: [String, Object],
                required: false
            },
        },
        data () {
            return {
                dateFrom: null,
                timeFrom: null,
                dateTo: null,
                timeTo: null,
                driverList: [],
                driverID: null,
                maxDate: new Date().toISOString().substring(0, 10),
                headers: [
                    {
                        text: 'Nazwa',
                        align: 'left',
                        value: 'name',
                    },
                    {
                        text: 'Pobierz',
                        sortable: false,
                        value: 'file_url'
                    },
                ],
                listRaport: [],
            }
        },
        watch: {
            message: function (val) {
                if(typeof val.data_type !== 'undefined' && val.data_type === 'update_required_reports') {
                    this.getListRaport();
                }
            },
        },
        created() {
            this.getListRaport();
        },
        methods: {
            getListRaport() {
                this.$http.get('reports/get_list') // Pobieranie listy raportów
                    .then((response) => {
                        this.listRaport = response.data;
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania listy raportów.'
                        });
                    });
            },
            generateRaport() {

                if(this.driverID) {
                    this.$http.post('report/generator/driver', {
                        date_from: (this.dateFrom ? this.dateFrom + ' ' + (this.timeFrom ? this.timeFrom : '00:00') : ''),
                        date_to: (this.dateTo ? this.dateTo + ' ' + (this.timeTo ? this.timeTo : '23:59') : ''),
                        driver_id: this.driverID,
                    })
                        .then((response) => {
                            if(response.data.message) {
                                this.$notify({
                                    group: 'foo',
                                    duration: 8000,
                                    title: 'Generowanie raportu',
                                    text: response.data.message
                                });
                                this.dateFrom = null;
                                this.timeFrom = null;
                                this.dateTo = null;
                                this.timeTo = null;
                                this.driverID = null;
                            }
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas pobierania generowania raportu.'
                            });
                        });
                } else {
                    this.$http.post('report/generator/total', {
                        date_from: (this.dateFrom ? this.dateFrom + ' ' + (this.timeFrom ? this.timeFrom : '00:00') : ''),
                        date_to: (this.dateTo ? this.dateTo + ' ' + (this.timeTo ? this.timeTo : '23:59') : ''),
                    })
                        .then((response) => {
                            if(response.data.message) {
                                this.$notify({
                                    group: 'foo',
                                    duration: 8000,
                                    title: 'Generowanie raportu',
                                    text: response.data.message
                                });
                                this.dateFrom = null;
                                this.timeFrom = null;
                                this.dateTo = null;
                                this.timeTo = null;
                            }
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas pobierania generowania raportu.'
                            });
                        });
                }
            }
        }
    }
</script>

<style lang="scss">
.theme--light .v-card:not(.search-card) .v-card__text {
    border-style: solid;
    border-width: 1px 0 1px;
    border-color: rgba(0, 0, 0, 0.1);
}
.theme--dark .v-card:not(.search-card) .v-card__text {
    border-style: solid;
    border-width: 1px 0 1px;
    border-color: rgba(255, 255, 255, 0.1);
}
</style>
